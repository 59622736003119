import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dados-resumo-holerite',
  templateUrl: './dados-resumo-holerite.component.html',
  styleUrls: ['./dados-resumo-holerite.component.css']
})
export class DadosResumoHoleriteComponent implements OnInit {
  @Input('qtdDependentes') qtdDependentes: number;
  @Input('baseINSS') baseINSS: number;
  @Input('baseSala') baseSala: number;
  @Input('salaLiq') salaLiq: number;
  @Input('fgts') fgts: number;
  

  constructor() { }

  ngOnInit() {
  }

}
