import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.css']
})
export class TermosComponent implements OnInit {
  @Input() showTermsForm: Boolean;  
  @Output() accepts: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() unaccepts: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  checked = false;

  constructor() { }

  ngOnInit() {
  }

  termsAccepts() {
    this.accepts.emit();
  }

  termsUnaccepts() {
    this.unaccepts.emit();

  }

}
