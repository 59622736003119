import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "../aws-exports";

import { TableModule } from 'primeng/table';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HoleriteComponent } from './components/holerite/holerite.component';
import { DadosTopoHoleriteComponent } from './components/dados-topo-holerite/dados-topo-holerite.component';
import { DadosResumoHoleriteComponent } from './components/dados-resumo-holerite/dados-resumo-holerite.component';
import { LoginComponent } from './components/login/login.component';
import { UserConfigComponent } from './components/user-config/user-config.component';
import { SelectMonthComponent } from './components/select-month/select-month.component';

import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import {LOCALE_ID} from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { ClockEventsComponent } from './components/clock-events/clock-events.component';
import { TermosComponent } from './components/termos/termos.component';
import { HttpClientModule } from '@angular/common/http';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

registerLocaleData(localePt, 'pt');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HoleriteComponent,
    DadosTopoHoleriteComponent,
    DadosResumoHoleriteComponent,
    LoginComponent,
    SelectMonthComponent,
    UserConfigComponent,
    MainComponent,
    ClockEventsComponent,
    TermosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TableModule,
    ScrollPanelModule,
    AmplifyUIAngularModule,
    HttpModule,
    HttpClientModule,
    CalendarModule,
    FormsModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    CardModule,
    ListboxModule,
    DialogModule,
    CheckboxModule,
    AccordionModule,
    ProgressSpinnerModule
  ],
  providers: [{
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
