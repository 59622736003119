import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-dados-topo-holerite',
  templateUrl: './dados-topo-holerite.component.html',
  styleUrls: ['./dados-topo-holerite.component.css']
})
export class DadosTopoHoleriteComponent implements OnInit {
  @Input('data') data: any;

  constructor() { }

  ngOnInit() {
  }

  formatDate(date, format = 'YYYY-MM-DD') {
    if (date)
      return moment(date.substr(0,10), format).format('DD/MM/YYYY');
    else
      return ''
  }

}
