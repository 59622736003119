import { Component, OnInit } from '@angular/core';

import { Auth } from 'aws-amplify';
import { Http, Headers } from '@angular/http';
import * as moment from 'moment';
import { jsPDF } from "jspdf";
require('jspdf-autotable');
import autoTable from 'jspdf-autotable';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-clock-events',
  templateUrl: './clock-events.component.html',
  styleUrls: ['./clock-events.component.css']
})
export class ClockEventsComponent implements OnInit {
  date: Date = new Date();
  isMobile = false;
  carregado = false;
  temDadosPonto = false;

  public isGeneratedPdf = false;

  data: any;
  mensagemErro = '';
  empresa = '';

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  async ngOnInit() {
    this.date.setMonth(this.date.getMonth()-1);
    this.loadData();
    if (typeof window.orientation !== 'undefined') { 
      this.isMobile = true;
    }
  }

  async changeDate() {
    this.carregado = false;
    this.loadData();
  }

  getTimeFromMins(mins) {
      if (mins >= 24 * 60 || mins < 0) {
          throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
      }
      var h = mins / 60 | 0,
          m = mins % 60 | 0;
      return moment.utc().hours(h).minutes(m).format("HH:mm");
  }

  formatMinute(minutes: string[]): string[] {
    let returnArray = [];
    for (let i = 0; i < minutes.length; i++) {
      returnArray.push(this.getTimeFromMins(minutes[i]))
    }
    
    return returnArray;
  }

  

  public minutosParaHoras(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }

  async loadData() {
    this.temDadosPonto = false;
    let tokenData = await Auth.currentSession(); 
    let currentUser = await Auth.currentUserInfo();
    this.empresa = tokenData.getIdToken().payload['custom:empresa'];

    var cabe = new Headers();
    cabe.append('Content-Type', 'application/json');
    cabe.append('token', currentUser.username == "00000000000" ? 'abc123' : tokenData.getAccessToken().getJwtToken());
    
    let month = this.date.getMonth()+1;
    let year = this.date.getFullYear();

    try {
      let data = await this.http.get(`https://tsm03p94gi.execute-api.us-east-1.amazonaws.com/default/RH-readClockEventData?month=${month}&year=${year}&empresa=${this.empresa}`, { headers : cabe }).toPromise();
      this.data = JSON.parse(data['_body']);
    } catch (error) {
      this.mensagemErro = JSON.parse(error['_body']).errorMessage;
      this.carregado = true;
      return;
    }

    let totalWorkTime = 0;
    let totalExtra50 = 0;
    let totalExtra100 = 0;
    //let totalWorked = 0;
    let totalMissed = 0;
    if (this.data['generalData'] && Object.keys(this.data.clockEvents['clockEventsData']).length > 0) {
      this.data.clockEvents.clockEventsData.details = this.data.clockEvents.clockEventsData.details.filter((item) => item);
      this.data.clockEvents.clockEventsData.details.map((item) => {
        if (item) {
          if (item['workTime'] > 0) {
            item['workTime'] = item['workTime'] / item.registers.length;
            totalWorkTime += item['workTime'];
          }

          if (item['50'] > 0) {
            item['50'] = item['50'] / item.registers.length;
            totalExtra50 += item['50'];
          }

          if (item['100'] > 0) {
            item['100'] = item['100'] / item.registers.length;
            totalExtra100 += item['100'];
          }

          if (item['missed'] > 0) {
            item['missed'] = item['missed'] / item.registers.length;
            totalMissed += item['missed'];
          }
          
          //totalWorked += +totalWorkTime;
        }
      })
      this.temDadosPonto = true;
      
      this.data.clockEvents.extra50 = totalExtra50;
      this.data.clockEvents.extra100 = totalExtra100;
      this.data.clockEvents.missed = totalMissed;
      this.data.clockEvents.normalTotal = totalWorkTime;
    }

    this.carregado = true;
    console.log(this.data.clockEvents);
  }

  salvar(index) {
    const doc = new jsPDF();

    let ponto = this.data.clockEvents;
    let data = [];
    ponto.clockEventsData.details.forEach((item) => {      
      data.push({
        day: moment(item.day).utc().format('DD'),
        weekday: item.weekday,
        //hor: item.grade,
        registers: (item.registers.length > 0 ? this.formatMinute(item.registers) + '   ' : '') + item.dayDesc,
        workTime: item.workTime > 0 ? this.minutosParaHoras(item.workTime) : '',
        missed: item.missed > 0 ? this.minutosParaHoras(item.missed) : '',
        extra50: item['50'] > 0 ? this.minutosParaHoras(item['50']) : '',
        extra100: item['100'] > 0 ? this.minutosParaHoras(item['100']) : ''
      });    
    });

    //autoTable(doc, { html: '#dataTable'});
    switch (this.empresa) {
      case 'incape':
        doc.addImage("../../../assets/images/logo-incape.png", "PBG", 10, 5, 35, 13); break;
      case 'gessner':
        doc.addImage("../../../assets/images/logo.png", "PBG", 10, 5, 25, 25); break;
    
      default:
        doc.addImage("../../../assets/images/RH-Fácil.png", "PBG", 10, 0, 45, 25); break;
        //doc.addImage("../../../assets/images/logo-incape.png", "PBG", 10, 5, 35, 13); break;
        break;
    }

    doc.setFontSize(17);
    doc.text('Cartão Ponto', 82, 14);

    doc.setFontSize(11);
    doc.setTextColor(255, 17, 0);
    doc.text('Período: ', 135, 7);
    let perido = moment(ponto.startDate.substr(0,10)).format('DD/MM/YYYY') + ' a ' + moment(ponto.endDate.substr(0,10)).format('DD/MM/YYYY');    
    doc.text(perido, 154, 7);

    doc.setTextColor(94, 94, 94);
    doc.setFontSize(9);
    doc.text('Empresa: ', 15, 27);
    doc.text('CNPJ:', 130, 27);


    doc.text('Nome:', 15, 32);

    doc.text('Banco:', 15, 37);
    doc.text('Agência:', 120, 37);
    doc.text('Conta:', 155, 37);

    doc.text('Cadastro: ', 15, 42);
    doc.text('Admissão:', 120, 42);

    doc.text('Local: ', 120, 47);
    doc.text('Cargo:', 15, 47);


    doc.setTextColor(0, 0, 0);
    doc.text(this.data.generalData.empresa, 34, 27);
    doc.text(this.data.generalData.cnpj, 143, 27);

    doc.text(this.data.generalData.nome, 29, 32);

    doc.text(this.data.generalData.banco ? this.data.generalData.banco : '', 29, 37);
    doc.text(this.data.generalData.agencia ? this.data.generalData.agencia : '12345', 134, 37);
    doc.text(this.data.generalData.conta ? this.data.generalData.conta : '', 166, 37);

    doc.text(''+this.data.generalData.cadastro, 34, 42);
    doc.text(moment(this.data.generalData.datAdmissao.substr(0,10)).format('DD/MM/YYYY'), 139, 42); 
    
    doc.text(this.data.generalData.local, 133, 47);
    doc.text(this.data.generalData.cargo, 28, 47);

    const startY = 52;
    autoTable(doc, {
        columns: [{ dataKey: 'day', title: 'Dia' },
                  { dataKey: 'weekday', title: 'Sem' },
                  //{ dataKey: 'hor', title: 'Hor' },
                  { dataKey: 'registers', title: 'Marcações'},
                  { dataKey: 'workTime', title: 'Trabalho' },
                  { dataKey: 'missed', title: 'Faltas' },
                  { dataKey: 'extra50', title: 'Extras 50%' },
                  { dataKey: 'extra100', title: 'Extras 100%' }],
        body: data,
        styles: {
          fontSize: 8
        },
        startY: startY,
        margin: { right: 3, left: 3 },
        columnStyles: {
          3: {halign: 'center'},
          4: {halign: 'center', textColor: 'red'},
          5: {halign: 'center', textColor: 'green'},
          6: {halign: 'center', textColor: 'green'}
        }
    });


    let finalY = startY + (data.length * 7) + 10;
    if (finalY < 130) {
      finalY += 11;
    }

    doc.text('Extra 50%:', 18, finalY);    
    doc.text(''+this.minutosParaHoras(this.data.clockEvents.extra50), 39, finalY);
    
    doc.text('Extra 100%:', 58, finalY);    
    doc.text(''+this.minutosParaHoras(this.data.clockEvents.extra100), 81, finalY);
    
    doc.text('Faltas:', 103, finalY);    
    doc.text(''+this.minutosParaHoras(this.data.clockEvents.missed), 116, finalY);

    doc.text('Trabalho:', 137, finalY);    
    doc.text(''+this.minutosParaHoras(this.data.clockEvents.normalTotal), 155, finalY);

    doc.setTextColor(0, 128, 0);
    doc.setTextColor(207, 205, 200);
    doc.setFontSize(9);
    doc.text('RH Fácil - Desenvolvido por DG Sistemas', 79, 293);

    let month = this.date.getMonth()+1;
    let year = this.date.getFullYear();
    //doc.autoPrint({variant: 'non-conform'});

    if (localStorage.getItem('isMobile') === 'S') {
      const dataSrc = doc.output('blob');
      this.isGeneratedPdf = true;
      var reader = new FileReader();
      reader.readAsDataURL(dataSrc);
      reader.onloadend = async () => {
        try {
          const base64data = reader.result as string;
          const url = await this.httpClient.post('https://tfs9ow0gg7.execute-api.us-east-1.amazonaws.com/default/RH-pdf', {
            name: new Date().getTime().toString(),
            file: base64data.split(',')[1]
          }).toPromise();
          window.parent.postMessage(url, '*');
        } finally {
          this.isGeneratedPdf = false;
        }
      }
    } else {
      const dataSrc = doc.output('bloburi');
      window.open(dataSrc.toString());
    }
    
    //doc.save(`holerite-${month}-${year}.pdf`);
    
  }

}
