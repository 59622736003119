import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'rhfacil';

  constructor(
    private router: Router
  ) {
    new Date().getTime().toString()
    if (window.location.search == '?isMobile=true') {
      localStorage.setItem('isMobile', 'S');
    }
  }

  async ngOnInit() {
    try {
      let data = await Auth.currentSession();
    } catch (error) {
      this.router.navigate(["/login"]);
    }
  }

}
