import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

import { HoleriteComponent } from "../holerite/holerite.component";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  selectedMenu = '';
  empresa = '';

  constructor() { }

  async ngOnInit() {
    this.selectedMenu = 'holerite';
    
    let tokenData = await Auth.currentSession(); 
    this.empresa = tokenData.getIdToken().payload['custom:empresa'];
  }

}
