import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Auth } from 'aws-amplify';

import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.css']
})

export class UserConfigComponent implements OnInit {
  tokenData = undefined;
  isRH = true;

  usuarioLog = '';
  usuario = '';
  senhaAtual = '';
  novaSenha = '';
  novaSenhaConfirmacao = '';
  listaUsuarioAcessos = [];
  listaUsuariosUltimoAcessos = [];

  constructor(private router: Router,
              private http: Http,
              private messageService: MessageService) { }

  async ngOnInit() { 
    this.tokenData = await Auth.currentSession(); 
    let groups = this.tokenData.getAccessToken().payload['cognito:groups'];
    this.isRH = groups && groups.indexOf('rh') > -1;
  }

  fechar() {
    this.router.navigate(["/"]);
  }

  async salvar() { 
    if (!this.usuario && !this.senhaAtual) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'Necessário informar a senha atual'}); return }
    if (!this.novaSenha) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'Necessário informar a nova senha.'}); return }
    if (!this.novaSenhaConfirmacao) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'Necessário informar a confirmação da nova senha.'}); return }
    if (this.novaSenha != this.novaSenhaConfirmacao) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'A nova senha e confirmação da senha devem ser iguais.'}); return }

    let username = this.tokenData.getAccessToken().payload.username;
    if (!this.isRH || (this.isRH && this.usuario == username)) {
      Auth.currentAuthenticatedUser().then(user => {
          Auth.changePassword(user, this.senhaAtual, this.novaSenha).then(value => {
            this.senhaAtual = '';
            this.novaSenha = '';
            this.novaSenhaConfirmacao = '';
            this.usuario = '';
            this.messageService.add({key: 'tc', severity:'success', summary: 'Sucesso', detail:'Sua senha foi alterada com sucesso.'});
          }).catch(error => {
            if (error.message.indexOf('Member must have length greater than or equal to 6') > -1) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'A senha deve possuir pelo menos 6 letras ou números.'}); return }
            if (error.message.indexOf('Incorrect username or password.') > -1) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'A senha atual está incorreta.'}); return }
            if (error.message.indexOf('Attempt limit exceeded, please try after some time.') > -1) { this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:'Você tentou muitas vezes, tente novamente mais tarde.'}); return }         

            this.messageService.add({key: 'tc', severity:'warn', summary: 'Atenção', detail:error.message}); return;
          });
      });
    } else {
      if (this.isRH && this.usuario != username) {
        var cabe = new Headers();
        cabe.append('Content-Type', 'application/json');
        cabe.append('token', this.tokenData.getAccessToken().getJwtToken());
    
        let data = await this.http.post(`https://w1r5ql31l6.execute-api.us-east-1.amazonaws.com/default/RH-changePwdCognitoUser`, {
          cpf: this.usuario,
          newPwd: this.novaSenha
        }, { headers : cabe }).toPromise();    

        this.messageService.add({key: 'tc', severity:'success', summary: 'Sucesso', detail:`Senha do usuário "${this.usuario}" alterada com sucesso.`});
      }
    }
  }

  buscarLog() {
    this.listaUsuarioAcessos = [];
    this.http.get(`https://dg-rhfacil.firebaseio.com/login_log/${this.usuarioLog}/.json`).toPromise().then((data) => {
      let objList = JSON.parse(data['_body']);
      Object.keys(objList).map((item) => {
        this.listaUsuarioAcessos.push({label: item, value: moment(item, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'), succeed: objList[item].logged == 'succeed'});
      });

      this.listaUsuarioAcessos.sort((a,b) => {
        let aData = moment(a.value);
        let bData = moment(b.value);

        if (aData.isBefore(bData)) {
          return 1
        } else {
          if (aData.isAfter(bData)) {
            return -1
          } else {
            return 0
          }
        }
      })
    })  
  }

  async buscarAllLog() {
    let currentUser = await Auth.currentUserInfo();
    let empresa = this.tokenData.getIdToken().payload['custom:empresa'];
    
    var cabe = new Headers();

    cabe.append('Content-Type', 'application/json');
    cabe.append('token', currentUser.username == "00000000000" ? 'abc123' : this.tokenData.getAccessToken().getJwtToken());

    let data = await this.http.get(`https://o1mjeztm89.execute-api.us-east-1.amazonaws.com/default/RH-getEmployee?empresa=${empresa}`, { headers: cabe }).toPromise();
    let employeesList = JSON.parse(data['_body']);

    this.listaUsuariosUltimoAcessos = [];
    this.http.get(`https://dg-rhfacil.firebaseio.com/login_log/.json`).toPromise().then((data) => {
      let objList = JSON.parse(data['_body']);

      for (let i = 0; i < employeesList.employees.length; i++) {
        let dateList = objList[employeesList.employees[i].NUMCPF];
        
        if (dateList) {
          let recentlyDate = moment().year(1900);
          Object.keys(dateList).map((date) => {
            if (dateList[date].logged == "succeed") {
              if (moment(date).isAfter(recentlyDate)) {
                recentlyDate = moment(date);
              }
            }
          });
  
          this.listaUsuariosUltimoAcessos.push({
            ...employeesList.employees[i],
            'recentlyDate': recentlyDate.format('DD/MM/YYYY HH:mm')
          });
        }
      }

      this.listaUsuariosUltimoAcessos.sort((a,b) => {
        let dateA = moment(a.recentlyDate, 'DD/MM/YYYY HH:mm');
        let dateB = moment(b.recentlyDate, 'DD/MM/YYYY HH:mm');
        if (dateA.isAfter(dateB)) {
          return 1
        } else {
          if (dateA.isBefore(dateB)) {
            return -1
          } else {
            return 0
          }
        }

      })
    })  
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
