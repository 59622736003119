import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() showMenu;
  @Input() selectedMenu;
  @Output() selectedMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
  }

  signOut() {
    this.confirmationService.confirm({
        message: 'Tem certeza que deseja sair?',
        accept: () => {
          Auth.signOut();
          this.router.navigate(["/login"]);
        }
    });
  }

  userConfig() {
    this.router.navigate(["/userConfig"]);
  }

  setSelectedMenu(menu) {
    this.selectedMenu = menu;
    this.selectedMenuChange.emit(menu);
  }

}
