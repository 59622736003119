import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
//import { Calendar } from 'primeng/primeng';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.scss']
})
export class SelectMonthComponent implements OnInit {
  @Input() date: Date;
  @Output() dateChange:EventEmitter<Date> = new EventEmitter<Date>();
  @ViewChild(Calendar, { static: true }) calendar: any;
  br = {
    firstDayOfWeek: 0,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
    monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
    monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
    today: 'Today',
    clear: 'Clear'
  }

  constructor() { }

  ngOnInit() {
  }

  prevMonth() {
    this.date.setMonth(this.date.getMonth() - 1);
    this.dateChange.emit(this.date);
    this.calendar.updateInputfield();
  }

  nextMonth() {
    this.date.setMonth(this.date.getMonth() + 1);
    this.dateChange.emit(this.date);
    this.calendar.updateInputfield();
  }

  changeDate(event) {
    this.dateChange.emit(event);
  }

}
