/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d84ce00a-7272-44d9-a116-be02a192814c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9SfDomojZ",
    "aws_user_pools_web_client_id": "5qmsko0bmluee6gp0sd52cbtog",
    "oauth": {}
};


export default awsconfig;
